import { X } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui';
import { TagItem } from './tags-selector';

export type TagProps = {
  tag: TagItem;
  onRemoveTag?: (item: TagItem) => void;
  onTagClick?: (item: TagItem) => void;
};

export const Tag: React.FC<TagProps> = ({ tag, onRemoveTag, onTagClick }) => {
  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Prevent event from bubbling up to the tag span
    if (onRemoveTag) {
      onRemoveTag(tag);
    }
  };

  return (
    <span
      className={cn(
        'mb-0.5 mr-1 mt-0.5 inline-flex h-[26px] cursor-pointer items-center whitespace-nowrap rounded-sm bg-blue-50 pl-2 text-sm text-primary transition-all duration-200',
        onTagClick && 'hover:bg-blue-100 hover:shadow-sm',
        !onRemoveTag && 'pr-3'
      )}
      onClick={() => onTagClick?.(tag)}
    >
      {tag.label}
      {onRemoveTag && (
        <Button
          type="button"
          variant="ghost"
          onClick={handleRemove}
          className={cn('h-full px-3 py-1 hover:bg-transparent')}
        >
          <X size={14} className="text-gray-700" />
        </Button>
      )}
    </span>
  );
};
