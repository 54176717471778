'use client';
import { cn } from '@/lib/utils';
import { Logo } from '@/components/svg';
import { HeaderNavItem } from './header-nav-item';
import { HeaderActionsButtons } from './header-actions-buttons';
import { useIsAllowed } from '@/lib/RBAC';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';

interface HeaderProps {
  className?: string;
}

export function Header({ className, ...props }: HeaderProps) {
  const CALLBACK_URL = `${process.env.NEXT_PUBLIC_LOGIN_URL}`;
  const { checkPermissions } = useIsAllowed();
  const t = useTranslations();
  const router = useRouter();

  return (
    <header
      className={cn(
        'flex w-full select-none flex-row items-center justify-between border border-b-zinc-200 px-4',
        className
      )}
      {...props}
    >
      <div className="w-20">
        <Logo width="26" height="28" className="cursor-pointer" onClick={() => router.push('/dashboard')} />
      </div>

      <nav>
        <ul className="flex flex-row space-x-9 font-medium">
          {checkPermissions([PermissionsTypes.VIEW_ACTIVITY_LOGS]) && (
            <HeaderNavItem link={{ text: t('Pages.AuditLog.title'), href: '/dashboard/audit-log' }} />
          )}
          {checkPermissions([PermissionsTypes.VIEW_LIST_OF_SERVICE_ROLES]) && (
            <HeaderNavItem link={{ text: t('Pages.ServiceRoles.title'), href: '/dashboard/service-roles' }} />
          )}
          {checkPermissions([PermissionsTypes.VIEW_TIMESHEETS_LIST]) && (
            <HeaderNavItem link={{ text: t('Pages.Timesheets.title'), href: '/dashboard/timesheets' }} />
          )}
        </ul>
      </nav>

      <div className="flex flex-row items-center space-x-1">
        <HeaderActionsButtons callbackUrl={CALLBACK_URL} />
      </div>
    </header>
  );
}
