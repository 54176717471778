'use client';
import { cn } from '@/lib/utils';

interface Props {
  label: string;
  value?: string | number;
  className?: string;
  withoutBorder?: boolean;
  pre?: boolean;
  forceLineBreak?: boolean;
  onClickByValue?: () => void;
  children?: React.ReactNode;
}

export function LabelValueItem({
  label,
  value,
  className,
  onClickByValue,
  withoutBorder,
  forceLineBreak,
  pre,
  children,
}: Props) {
  return (
    <div className={cn('flex w-full flex-col', className)}>
      <h4 className="ml-1 mt-1 select-none text-sm font-medium text-gray-700">{label}</h4>
      <div
        className={cn(
          'mt-2 flex min-h-10 w-full flex-wrap items-center space-y-1 rounded-md border border-gray-300 bg-transparent px-3 py-1 shadow-sm',
          withoutBorder && 'border-none px-0 shadow-none'
        )}
      >
        {children ? (
          children
        ) : (
          <p
            onClick={onClickByValue ?? undefined}
            className={cn(
              'text-sm font-normal text-slate-900',
              onClickByValue && 'cursor-pointer underline',
              forceLineBreak && 'break-all',
              pre && 'whitespace-pre-wrap'
            )}
          >
            {value ?? '---'}
          </p>
        )}
      </div>
    </div>
  );
}
