'use client';
import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Input } from '@/components/form';
import { Loader } from '@googlemaps/js-api-loader';
import { parsePlaceResult, AddressInfo } from '@/components/helpers';

interface GPlacesAutocompleteProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onPlaceChanged?: (value: AddressInfo) => void;
  onChanges: (event: string | AddressInfo) => void;
  returnType?: 'string' | 'object';
  values?: string;
}
export function GooglePlacesInput({
  values,
  onPlaceChanged,
  onChanges,
  returnType = 'string',
  ...props
}: GPlacesAutocompleteProps) {
  const apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [data, setData] = useState('');

  const initializeService = () => {
    if (!window.google) throw new Error('[google-places-autocomplete]: Google script not loaded');
    if (!window.google.maps) throw new Error('[google-places-autocomplete]: Google maps script not loaded');
    if (!window.google.maps.places)
      throw new Error('[google-places-autocomplete]: Google maps places script not loaded');

    const options = {
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'geometry'],
    };
    const autocomplete = new google.maps.places.Autocomplete(inputRef.current as HTMLInputElement, options);
    autocomplete.addListener('onChange', () => onChanges(inputRef.current?.value as string));
    autocomplete.addListener('place_changed', () => handlePlaceChanged(autocomplete));
  };

  useEffect(() => {
    if (values || values === '') {
      setData(values);
    }
  }, [values]);

  useEffect(() => {
    setTimeout(() => (document.body.style.pointerEvents = ''), 0);

    const init = async () => {
      if (!apiKey) throw new Error('Google API_KEY is required');

      try {
        if (!window.google || !window.google.maps || !window.google.maps.places) {
          await new Loader({ apiKey }).importLibrary('places');
        }
        initializeService();
      } catch (error: any) {
        console.error('GOOGLE API LOAD:', error);
      }
    };

    init();
  }, []);

  const handlePlaceChanged = async (address: google.maps.places.Autocomplete) => {
    const place: google.maps.places.PlaceResult = address.getPlace();

    if (!place || !place.geometry) {
      return;
    }

    if (returnType === 'string') {
      onChanges(inputRef.current?.value as string);
    } else {
      const values = parsePlaceResult(place);
      if (onPlaceChanged) {
        onPlaceChanged(values);
        onChanges(values);
      }
    }
  };

  return (
    <Input
      {...props}
      onChange={(e) => {
        setData(e.target.value);
        if (returnType === 'string') {
          onChanges(e.target.value);
        }
      }}
      value={data}
      ref={inputRef}
    />
  );
}
