'use client';
import { Button } from '@/components/ui';
import { cn } from '@/lib/utils';
import { usePathname } from '@/navigation';
import { useRouter } from 'next/navigation';

interface Props {
  className?: string;
  icon: any;
  title: string;
  path: string;
  openedMenu: boolean;
}

export function LeftSideNavItem({ className, icon, title, path, openedMenu }: Props) {
  const Icon = icon;
  const pathname = usePathname();
  const router = useRouter();

  const handleRedirect = () => {
    router.push(path);
  };

  return (
    <li
      onClick={handleRedirect}
      className={cn(
        'mr-8 flex cursor-pointer flex-row items-center whitespace-nowrap rounded-sm text-gray-500 transition-colors duration-300 hover:bg-secondary',
        className,
        pathname === path && 'text-white hover:bg-primary'
      )}
    >
      <Button variant={pathname === path ? 'default' : 'ghost'} size="icon" className={cn(
              'min-w-9 text-gray-400 transition-all duration-300',
              openedMenu && 'rounded-r-none',
            )}>
        <Icon className={cn('w-6', pathname === path && 'text-white')} />
      </Button>
      {
        <p className={cn('flex min-h-9 w-full flex-row items-center justify-between rounded-r-sm pl-[17px] pr-3 text-sm font-medium text-gray-800 transition-colors duration-300 hover:bg-secondary', pathname === path && openedMenu && 'bg-primary/90 text-white hover:bg-primary')}>
          {title}
        </p>
      }
    </li>
  );
}
