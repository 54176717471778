'use client';
import { Form, FormControl, FormField, FormItem, FormMessage, Textarea } from '@/components/form';
import { Button } from '@/components/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { SendHorizontal } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const FormSchema = z.object({
  comment: z.string().min(1, { message: 'Forms.comment.required' }).max(1000, { message: 'Forms.comment.max' }),
});

type Props = {
  onSubmit: (content: string) => void;
  isPending: boolean;
};

export const CommentForm = ({ onSubmit, isPending = false }: Props) => {
  const t = useTranslations();

  const form = useForm<z.infer<typeof FormSchema>>({
    mode: 'onTouched',
    resolver: zodResolver(FormSchema),
  });

  function onSubmitHandle(data: z.infer<typeof FormSchema>) {
    onSubmit(data.comment);
    form.setValue('comment', '');
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmitHandle)}
        className="relative flex w-full flex-row items-center space-x-4 border-t border-gray-200 pt-4"
      >
        <FormField
          control={form.control}
          name="comment"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <Textarea placeholder={t('Forms.comment.placeholder')} className="resize-none" {...field} />
              </FormControl>
              <FormMessage className="absolute" />
            </FormItem>
          )}
        />
        <Button variant="ghost" size="icon" type="submit" disabled={isPending || !form.formState.isValid}>
          <SendHorizontal />
        </Button>
      </form>
    </Form>
  );
};
