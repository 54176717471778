'use client';
import { CircleAlert, Eye, EyeOff } from 'lucide-react';

import { cn } from '@/lib/utils';
import { forwardRef, useState } from 'react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  leftIcon?: any;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, leftIcon, type, hasError, ...props }, ref) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }

  const LeftIcon = leftIcon;

  return (
    <div className="relative w-full">
      {leftIcon && <LeftIcon className="absolute left-3 top-2 w-4 text-gray-400"></LeftIcon>}
      <input
        type={isPasswordVisible ? 'text' : type}
        className={cn(
          'flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-1 text-sm text-slate-900 shadow-sm outline-none transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
          className,
          hasError && 'border-destructive pr-8 invalid:[&:not(:placeholder-shown)]:border-2',
          leftIcon && 'pl-8',
          type === 'password' && 'pr-8'
        )}
        ref={ref}
        {...props}
      />
      {hasError && type !== 'password' && <CircleAlert className="absolute right-3 top-2 w-4 text-destructive" />}

      {type === 'password' && (
        <button
          type='button'
          className={cn(
            'absolute inset-y-0 right-0 flex items-center px-3 text-gray-400',
            hasError && 'text-destructive'
          )}
          onClick={togglePasswordVisibility}
        >
          {isPasswordVisible ? <EyeOff className="w-4" /> : <Eye className="w-4" />}
        </button>
      )}
    </div>
  );
});
Input.displayName = 'Input';

export { Input };
