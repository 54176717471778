'use client';

import { type ReactNode, createContext, useRef, useContext } from 'react';
import { useStore } from 'zustand';

import { type SendBirdStore, createSendBirdStore, initSendBirdStore } from '@/stores/send-bird-store';

export type SendBirdStoreApi = ReturnType<typeof createSendBirdStore>;

export const SendBirdStoreContext = createContext<SendBirdStoreApi | undefined>(undefined);

export interface SendBirdStoreProviderProps {
  children: ReactNode;
}

export const SendBirdStoreProvider = ({ children }: SendBirdStoreProviderProps) => {
  const storeRef = useRef<SendBirdStoreApi>();
  if (!storeRef.current) {
    storeRef.current = createSendBirdStore(initSendBirdStore());
  }

  return <SendBirdStoreContext.Provider value={storeRef.current}>{children}</SendBirdStoreContext.Provider>;
};

export const useSendBirdStore = <T,>(selector: (store: SendBirdStore) => T): T => {
  const counterStoreContext = useContext(SendBirdStoreContext);

  if (!counterStoreContext) {
    throw new Error(`useSendBirdStore must be used within SendBirdStoreProvider`);
  }

  return useStore(counterStoreContext, selector);
};
