'use client';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';

interface Props {
  className?: string;
  value: boolean;
  label: string;
}

export function ToggleView({ className, value, label }: Props) {
  const t = useTranslations();
  return (
    <div className='flex  flex-row items-center'>
      <h4 className="mr-5 select-none text-sm font-medium text-gray-700">{label}</h4>
      <div
        className={cn(
          'flex h-7 w-16 flex-row items-center justify-center rounded-full border border-gray-300',
          className
        )}
      >
        <div className={cn('mr-[6px] h-[6px] w-[6px] rounded-full', value ? 'bg-green-500' : 'bg-gray-300')}></div>
        <span className="text-sm text-gray-700">{value ? t('Common.yes') : t('Common.no')}</span>
      </div>
    </div>
  );
}
