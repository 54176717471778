'use client';
import { useTranslations } from 'next-intl';
import { Button } from '@/components/ui';
import { cn } from '@/lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useRouter } from 'next/navigation';

interface BackButtonProps {
  className?: string;
  url?: string;
}

export function BackButton({ className, url, ...props }: BackButtonProps) {
  const t = useTranslations();
  const router = useRouter();

  const handleBackRedirect = () => {
    if (url) {
      router.push(url);
    } else {
      router.back();
    }
  };

  return (
    <Button
      onClick={handleBackRedirect}
      className={cn('absolute left-5 top-10', className)}
      {...props}
      type="button"
      size="lg"
      variant="ghost"
    >
      <div className="flex">
        <ChevronLeft className="mr-1 h-5 w-5" />
        {t('Buttons.back')}
      </div>
    </Button>
  );
}
