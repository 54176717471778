import { Colors } from '@/common/enums';
import { Chips } from '../chips';
import dayjs from '@/lib/dayjsConfig';
import { CommentData } from '@/hooks/fetchers/queries/useCommentsQuery';
import { compileFullName } from '@/lib/utils';
import { USER_ROLES_COLORS } from '@/common/constants/chips-colors';

type Props = {
  comment: CommentData
};

export const Comment = ({ comment }: Props) => {
  const parseDate = (date: string) => {
    return dayjs(date).format('MM.DD.YYYY HH:mm');
  };

  return (
    <div className="rounded-sm border border-gray-200 p-4">
      <header className="flex flex-row items-start justify-between border-b border-gray-200 pb-4">
        <div className="flex flex-row items-center">
          <span className="mr-2 text-base font-semibold text-gray-950">{compileFullName(comment.author)}</span>
          <Chips color={USER_ROLES_COLORS[comment.author.role]} title={comment.author.role} />
        </div>
        <span className='text-gray-600 text-xs'>{parseDate(comment.createdAt)}</span>
      </header>
      <div className="text-gray-800 text-sm pt-4">{comment.text}</div>
    </div>
  );
};
