'use client';
import { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui';
import { Trash2 } from 'lucide-react';

interface ConfirmDialogProps {
  title: string;
  description?: string;
  okBtnName?: string;
  noBtnName?: string;
  /**
   * Type of confirm modal.
   * Depending on the type, there will be different icons and buttons.
   * Default: "warning"
   */
  type?: 'delete' | 'warning';
  /**
   * There should be a button that will act as a trigger to open a dialog.
   */
  children?: ReactNode;
  onConfirm: (value?: any) => any;
  onCancel?: () => any;
}

export type ConfirmDialogRef = {
  open: (data?: any) => void;
};

// export function ConfirmDialog({ title, description, type = 'warning', children, onConfirm }: ConfirmDialogProps) {
  const ConfirmDialog = forwardRef<ConfirmDialogRef | undefined, ConfirmDialogProps>(({ title, description = '', type = 'warning', okBtnName, noBtnName, children, onConfirm, onCancel }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<{title?: string, description?: string, value?: any}>({});

  const handleConfirm = () => {
    onConfirm(data && data.value ? data.value : null);
    setIsOpen(false);
  };

  const handleCancel = () => {
    onCancel?.();
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setData(data);
      setIsOpen(true);
    },
  }));

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {children && <DialogTrigger asChild>{children}</DialogTrigger>}
      <DialogContent className="sm:max-w-md">
        <DialogHeader className="flex-row">
          {type === 'delete' && (
            <div className="mr-4 flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
              <Trash2 className="text-destructive" />
            </div>
          )}
          <div className="space-y-1.5">
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>{data.description ? data.description : description}</DialogDescription>
          </div>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2"></div>
        </div>
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild>
            <Button onClick={handleCancel} className="w-20" size="lg" type="button" variant="outline">
              {noBtnName ? noBtnName : 'Cancel'}
            </Button>
          </DialogClose>

          {type === 'delete' && (
            <Button onClick={handleConfirm} className="w-20" size="lg" type="button" variant="destructive">
              {okBtnName ? okBtnName : 'Delete'}
            </Button>
          )}
          {type === 'warning' && (
            <Button onClick={handleConfirm} className="w-20" size="lg" type="button" variant="default">
              {okBtnName ? okBtnName : 'Apply'}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
});

ConfirmDialog.displayName = 'ConfirmDialog';

export { ConfirmDialog };