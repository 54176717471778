import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { RoleTypes } from '@/lib/RBAC/enums/role-types';

export interface CommentData {
  id: string;
  text: string;
  createdAt: string;
  author: Author;
}

interface Author {
  firstName: string;
  lastName: string;
  role: RoleTypes;
}

export type Options = Omit<UseQueryOptions<unknown, DefaultError, CommentData[]>, 'queryKey' | 'queryFn'>;

export const useCommentsQuery = (serviceId?: string, options?: Options) => {
  const _query = async (): Promise<CommentData[]> => {
    if (serviceId) {
      const { data } = await axiosInterceptorInstance.get(`comments/${serviceId}`);
      return data;
    } else {
      return [];
    }
  };

  return useQuery({
    queryKey: ['comments', serviceId],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
