'use client';
import { useCallback, useEffect, useState } from 'react';
import { PermissionsTypes } from '../enums/permissions-types';
import { isAllowed } from '../utils/utils';
import { useSession } from 'next-auth/react';
import { RoleTypes } from '../enums/role-types';

export const useIsAllowed = () => {
  const { data: session, status } = useSession();
  const [currentRole, setCurrentRole] = useState<RoleTypes | null>(null);


  useEffect(() => {
    if (session && session.user.roles) {
      setCurrentRole(session.user.roles[0]);
    }
  }, [session, status])

  const checkPermissions = useCallback(
    (permissions: PermissionsTypes[]) => {
      if (!session || !session.user.roles) {
        return false;
      }

      const roles = session.user.roles;

      if (roles && roles[0]) {
        return isAllowed(roles[0], permissions);
      } else {
        return false;
      }
    },
    [session, status]
  );

  return { checkPermissions, status, session, currentRole };
};
