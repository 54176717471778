'use client';
import { Logout } from '@/components/svg';
import { Button } from '@/components/ui';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { UserSquare } from '@/components/svg';
import { useQueryClient } from '@tanstack/react-query';
import LocaleSwitcher, { Locale } from './locale-switcher';
import { useLocale } from 'next-intl';
import { useState } from 'react';

export function HeaderActionsButtons({ callbackUrl }: { callbackUrl: string }) {
  const [lng, setLng] = useState(0);
  const locale = useLocale() as Locale;
  const queryClient = useQueryClient();
  const router = useRouter();
  const handleLogOut = () => {
    queryClient.clear();
    signOut({ callbackUrl });
  };
  const handleRedirectToProfile = () => {
    router.push('/dashboard/my-profile');
  };

  return (
    <>
      {process.env.NEXT_PUBLIC_ENV === 'develop' || process.env.NEXT_PUBLIC_ENV === 'local' ? (
        <>
          {lng === 8 && <LocaleSwitcher className="mr-2" locale={locale} />}
          <div className="absolute right-0 top-0 h-5 w-5" onClick={() => setLng((prev) => ++prev)}>
            {' '}
          </div>
        </>
      ) : null}
      <Button size="icon" variant="ghost">
        <UserSquare onClick={handleRedirectToProfile} className="h-6 w-6 text-gray-700" />
      </Button>
      <Button size="icon" variant="ghost" onClick={handleLogOut}>
        <Logout className="h-6 w-6 text-gray-700" />
      </Button>
    </>
  );
}
