'use client';
import { cn } from '@/lib/utils';
import { Link, usePathname } from '@/navigation';

interface Props {
  path: string;
  title: string;
  onClick: () => void;
}

export function LeftSideNavSubitem({ path, title, onClick }: Props) {
  const pathname = usePathname();

  return (
    <Link
      onClick={onClick}
      href={path}
      className={cn(
        'my-1 flex h-9 min-h-9 cursor-pointer flex-row items-center rounded-sm pl-[17px] text-sm text-gray-800 transition-colors duration-300 hover:bg-secondary',
        pathname === path && 'bg-secondary'
      )}
    >
      {title}
    </Link>
  );
}
