'use client';

import { Check, Info, OctagonAlert, TriangleAlert } from 'lucide-react';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from './toast';
import { ToastTypeEnums, useToast } from './use-toast';

export function Toaster() {
  const { toasts } = useToast();

  let icon = (type: ToastTypeEnums) => {
    switch (type) {
      case ToastTypeEnums.SUCCESS:
        return <Check className='text-green-900 min-w-6' />;
      case ToastTypeEnums.INFO:
        return <Info className='text-primary min-w-6' />;
      case ToastTypeEnums.WARNING:
        return <TriangleAlert className='text-yellow-500 min-w-6' />;
      case ToastTypeEnums.ERROR:
        return <OctagonAlert className='text-destructive min-w-6' />;
      default:
        return <Check className='min-w-6'/>;
    }
  };

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        typeIcon = ToastTypeEnums.SUCCESS,
        actionPosition = 'bottom',
        ...props
      }) {
        return (
          <Toast key={id} {...props}>
            {icon(typeIcon)}
            <div className="grid gap-1">
              {title && (
                <ToastTitle>
                   {title}
                </ToastTitle>
              )}
              {description && <ToastDescription>{description}</ToastDescription>}
              {actionPosition === 'bottom' && action}
            </div>
            {actionPosition === 'right' && action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
