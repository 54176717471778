'use client';
import { cn } from '@/lib/utils';
import { Link, usePathname } from '@/navigation';

interface Params {
  link: {
    href: string;
    text: string;
  };
  className?: string;
}

export function HeaderNavItem({ link, className }: Params) {
  const pathname = usePathname();

  return (
    <li>
      <Link
        className={cn(
          'flex h-14 items-center rounded-[2px] border-b-2 border-transparent text-sm font-medium text-gray-400 transition-colors duration-300 hover:border-primary hover:text-primary',
          className,
          pathname === link.href && 'border-primary text-primary'
        )}
        href={link.href}
      >
        {link.text}
      </Link>
    </li>
  );
}
