'use client';
import { Comment } from './comment';
import { CommentForm } from './comments-form';
import { useCommentsQuery } from '@/hooks/fetchers/queries/useCommentsQuery';
import { useCommentsMutation } from '@/hooks/fetchers/mutations/useCommentsMutation';
import { useEffect, useRef } from 'react';
import { cn } from '@/lib/utils';

type Props = {
  serviceId: string;
  scrollAreaClassName?: string;
};

export const CommentsList = ({ serviceId, scrollAreaClassName }: Props) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { data: comments, refetch: refetchComments, isFetched } = useCommentsQuery(serviceId);

  const { mutate: createComment, isPending: isCreating } = useCommentsMutation({
    onSuccess: () => refetchComments(),
  });

  const addComment = (text: string) => {
    createComment({ serviceId, text });
  };

  const scrollToBottom = (isSmooth: boolean = false) => {
    scrollRef.current?.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: isSmooth ? 'smooth' : 'instant',
    });
  };

  useEffect(() => {
    setTimeout(() => scrollToBottom(), 0);
  }, [isFetched]);

  useEffect(() => {
    if (isFetched) {
      setTimeout(() => scrollToBottom(true), 100);
    }
  }, [comments]);


  return (
    <div className="mt-4">
      <div ref={scrollRef} className={cn("h-[400px] space-y-4 overflow-auto", scrollAreaClassName)}>
        {comments?.map((comment, index) => <Comment key={index} comment={comment} />)}
      </div>
      <CommentForm isPending={isCreating} onSubmit={addComment} />
    </div>
  );
};
