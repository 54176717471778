'use client';
import { Button } from '@/components/ui';
import { useFilePicker } from '@/lib/file-chooser';
import { FileAmountLimitValidator, FileSizeValidator, FileTypeValidator } from '@/lib/file-chooser/validators';
import { cn } from '@/lib/utils';
import { CloudUpload } from 'lucide-react';

import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { toast, ToastTypeEnums } from './toast';

interface Props {
  accept?: string[];
  allowTypes?: string[];
  maxSizeMb?: number;
  maxSizeText: string;
  typeText: string;
  className?: string;
  onSelect: (file: File) => void;
}

export function UploadFileArea({ accept, allowTypes, maxSizeMb, maxSizeText, typeText, className, onSelect }: Props) {
  const [fileEnter, setFileEnter] = useState(false);
  const t = useTranslations();

  const getErrorText = (reason: string): string => {
    switch (reason) {
      case 'MAX_AMOUNT_OF_FILES_EXCEEDED':
        return t('Toasts.MAX_AMOUNT_OF_FILES_EXCEEDED');
      case 'FILE_SIZE_TOO_LARGE':
        return t('Toasts.FILE_SIZE_TOO_LARGE', { size: maxSizeText });
      case 'FILE_TYPE_NOT_ACCEPTED':
        return t('Toasts.FILE_TYPE_NOT_ACCEPTED', { type: typeText });
      default:
        return t('Toasts.SELECT_FILE_ERROR');
    }
  };

  const { openFilePicker, parseFileTarget, filesContent, loading, errors, plainFiles, clear } = useFilePicker({
    multiple: true,
    readAs: 'DataURL', // available formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: accept ? accept : ['.pdf'],
    // readFilesContent: false, // ignores file content,
    validators: [
      new FileAmountLimitValidator({ min: 1, max: 1 }),
      new FileSizeValidator({ maxFileSize: (maxSizeMb ? maxSizeMb : 10) * 1024 * 1024 /* 10 MB */ }),
      new FileTypeValidator(allowTypes ? allowTypes : ['pdf']),
    ],
    onFilesRejected: ({ errors }) => {
      let jsxElement = null;
      if (Array.isArray(errors)) {
        jsxElement = (
          <ul className="ml-3 list-disc">
            {errors.map((value: any, index) => (
              <li key={`${value.name}_${index}`}>
                {value.causedByFile && <strong>{value.causedByFile.name}:</strong>} {getErrorText(value.reason)}
              </li>
            ))}
          </ul>
        );
      }
      toast({
        title: 'Warning',
        typeIcon: ToastTypeEnums.WARNING,
        description: jsxElement ? jsxElement : t('Toasts.SELECT_FILE_ERROR'),
      });
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      handleSelect(plainFiles[0]);
    },
  });

  const handleSelect = (file: File) => {
    onSelect(file);
  };

  return (
    <div className="pb-4 pt-2">
      <div
        className={cn(
          'flex h-96 w-full flex-col items-center justify-center rounded-md border border-dashed border-blue-200 bg-blue-25',
          fileEnter && 'border-blue-300 bg-blue-50',
          className
        )}
        onDragOver={(e) => {
          e.preventDefault();
          setFileEnter(true);
        }}
        onDragLeave={(e) => {
          setFileEnter(false);
        }}
        onDragEnd={(e) => {
          e.preventDefault();
          setFileEnter(false);
        }}
        onDrop={(e) => {
          e.preventDefault();
          setFileEnter(false);
          let _files: File[] = [];
          if (e.dataTransfer && e.dataTransfer.items) {
            [...(e.dataTransfer.items as any)].forEach((item, i) => {
              if (item.kind === 'file') {
                _files.push(item.getAsFile());
              }
            });
          } else {
            [...(e.dataTransfer.files as any)].forEach((file, i) => {
              _files.push(file);
            });
          }
          parseFileTarget(_files);
        }}
      >
        <CloudUpload className="h-10 w-10 text-gray-400" />
        <h3 className="mb-2 mt-4 text-base font-semibold text-black">{t('Common.selectFileDnD')}</h3>
        <p className="font-normal text-black/40">
          {t('Common.chooseFileDesc', { size: maxSizeText, type: typeText })}
        </p>
        <Button type='button' onClick={() => openFilePicker()} size="sm" className="mt-4 w-28">
          {t('Buttons.selectFile')}
        </Button>
      </div>
    </div>
  );
}
