import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from '@/components/common';
import { cn } from '@/lib/utils';
import lodash from 'lodash';
import { useCallback, useState } from 'react';

const DEBOUNCE_DELAY = 500;

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onSelectValue: (value: { value: string | number; label: string }) => void;
  source: (search: string) => Promise<{ value: string | number; label: string }[]>;
}
export function AutoComplete({ className, onSelectValue, source }: InputProps) {  
  const [suggestions, setSuggestions] = useState<{ value: string | number; label: string }[]>([]);
  const [keyword, setKeyword] = useState('');
  const [state, setState] = useState({
    loading: false,
    selected: false,
  });

  const handleOnInputChange = async (value: string) => {
    if (value && value.length > 2 && source) {
      setState((prev) => {
        return {
          ...prev,
          selected: false,
          loading: true,
        };
      });
      const suggestions = await source(value);
      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
      setSuggestions(suggestions);
    } else {
      setSuggestions([]);
    }
  };

  const selectSuggestion = (suggestion: { value: string | number; label: string }) => {
    setState((prev) => {
      return {
        ...prev,
        selected: true,
      };
    });
    setKeyword(suggestion.label);
    onSelectValue(suggestion);
    setSuggestions([]);
  };

  const onChangeCaptureHandler = useCallback(lodash.debounce(handleOnInputChange, DEBOUNCE_DELAY), []);

  return (
    <Command shouldFilter={false} className={cn('rounded-md border border-gray-300', className)}>
      <CommandInput
        value={keyword}
        onValueChange={(value) => {
          setKeyword(value);
          setState((prev) => ({
            ...prev,
            loading: value.length > 2,
          }));
          onChangeCaptureHandler(value);
        }}
        placeholder="Search..."
      />
      <CommandList>
        {!state.loading && keyword.length > 2 && !state.selected && (
          <CommandEmpty>No results found.</CommandEmpty>
        )}
        {state.loading && <CommandEmpty>Fetching words…</CommandEmpty>}
        {suggestions.length > 0 &&
          suggestions.map((suggestion) => (
            <CommandItem
              className="cursor-pointer"
              value={JSON.stringify(suggestion)}
              onSelect={(value) => selectSuggestion(JSON.parse(value))}
              key={suggestion.value}
            >
              <span>{suggestion.label}</span>
            </CommandItem>
          ))}
      </CommandList>
    </Command>
  );
}
