import axios from 'axios';
import { getCsrfToken, getSession } from 'next-auth/react';
import { toast, ToastTypeEnums } from './components/common';
import { AUTH_JWT_ACTIONS } from './common/constants/common';
import { QueryClient } from '@tanstack/react-query';
import { RoleTypes } from './lib/RBAC/enums/role-types';
import { signOut } from 'next-auth/react';
import dayjs from 'dayjs';
import mem from 'mem';
import { JWT } from 'next-auth';

const CALLBACK_URL = `${process.env.NEXT_PUBLIC_LOGIN_URL}`;
const HOST_URL = `${process.env.NEXT_PUBLIC_HOST_URL}`;
const queryClient = new QueryClient();
let waitForRefresh = false;

const updateSession = async (
  action: AUTH_JWT_ACTIONS,
  payload?: {
    role?: RoleTypes;
    accessToken?: string;
    refreshToken?: string;
    sendBird?: string;
    refreshTokenExpiresAt?: string;
  }
) => {
  let _body = { action, data: payload };

  await fetch(`${HOST_URL}/api/auth/session`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      csrfToken: await getCsrfToken(),
      data: _body,
    }),
  });
};

export const refreshAccessToken = mem(
  async (refreshTokenId: string): Promise<any | { error: string } | null> => {
    const response = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/auth/refresh/`, {
      tokenId: refreshTokenId,
    });
    return response.data;
  },
  {
    maxAge: 5_000,
    cacheKey: (arguments_) => arguments_.join(','),
  }
);

const axiosInterceptorInstance = () => {
  const instance = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}/`,
  });
  instance.interceptors.request.use(
    async (request) => {
      const session = await getSession();
      if (session) {
        request.headers['Authorization'] = `Bearer ${session.accessToken}`;
        request.headers['X-Time-Zone'] = dayjs.tz.guess();
        request.headers['X-User-Role'] = session.user.roles;
      }
      return request;
    },
    (error) => {
      console.log(`%c DEBUG LOG: Handle request errors`, 'color: yellow');
      console.error(error);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const status = error.response ? error.response.status : 0;
      const msg =
        error.response && error.response.data && error.response.data.message ? error.response.data.message : null;
      switch (status) {
        case 403:
          // toast({
          //   title: 'Warning',
          //   typeIcon: ToastTypeEnums.WARNING,
          //   description: msg || `You don't have such permissions`,
          // });
          setTimeout(() => {
            window.location.reload();
          }, 3000)
          break;
        case 401:
          const session = await getSession();
          if (session && session.user && session.user.refreshTokenExpiresAt * 1000 > Date.now()) {
            const originalRequest = error.config;
            if (!originalRequest._retry && !waitForRefresh) {
              waitForRefresh = true;
              originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
              try {
                const data = await refreshAccessToken(session.user.refreshToken);
                const { accessToken, refreshTokenId: refreshToken, sendBird, refreshTokenExpiresAt } = data;
                // Store the new access and refresh tokens.
                // Update the authorization header with the new access token.
                instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                await updateSession(AUTH_JWT_ACTIONS.REFRESH_MANUAL, {
                  accessToken,
                  refreshToken,
                  sendBird,
                  refreshTokenExpiresAt
                });
                waitForRefresh = false;
                return instance(originalRequest); // Retry the original request with the new access token.
              } catch (refreshError) {
                // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
                console.error(`%c DEBUG LOG: REFRESH TOKEN ERROR`, 'color: red');
                console.error(`%c DEBUG LOG: `, refreshError);
                
                waitForRefresh = false;
                queryClient.clear();
                signOut({ callbackUrl: CALLBACK_URL });
                return Promise.reject(refreshError);
              }
            }
          } else {
            console.warn(`%c DEBUG LOG: REFRESH TOKEN IS UNDEFINED OR EXPIRED, AUTO LOGOUT`, 'color: yellow');
            queryClient.clear();
            signOut({ callbackUrl: CALLBACK_URL });
          }
          break;
        case 422:
          const currentRole =
            error.response && error.response.data && error.response.data.currentUserRole
              ? error.response.data.currentUserRole
              : null;

          if (currentRole) {
            instance.defaults.headers.common['X-User-Role'] = currentRole;
            await updateSession(AUTH_JWT_ACTIONS.UPDATE_ROLE, { role: currentRole });
            queryClient.invalidateQueries();
            toast({
              title: 'Warning',
              typeIcon: ToastTypeEnums.WARNING,
              description: 'Your role has been changed, please refresh the page.',
            });
          } else {
            await updateSession(AUTH_JWT_ACTIONS.REFRESH);
          }

          // if (currentRole) {
          //   await updateSession(AUTH_JWT_ACTIONS.REFRESH);
          //   await updateSession(AUTH_JWT_ACTIONS.UPDATE_ROLE, {role: currentRole});
          //   queryClient.invalidateQueries();
          //   toast({
          //     title: 'Warning',
          //     typeIcon: ToastTypeEnums.WARNING,
          //     description: msg || 'Your role has been updated, please refresh the page.',
          //   });
          // } else {
          //   toast({
          //     title: 'Warning',
          //     typeIcon: ToastTypeEnums.WARNING,
          //     description: msg || `Oops! Something went wrong. The data you provided couldn’t be processed.`,
          //   });
          // }
          break;
        case 400:
          let jsxElement = null;
          if (Array.isArray(msg)) {
            jsxElement = (
              <ul className="ml-3 list-disc">
                {msg.map((value, index) => (
                  <li key={`${value}_${index}`}>{value}</li>
                ))}
              </ul>
            );
          }
          toast({
            title: 'Warning',
            typeIcon: ToastTypeEnums.WARNING,
            description: jsxElement ? jsxElement : msg || `Validation error`,
          });
          break;
        case 404:
          toast({
            title: 'Warning',
            typeIcon: ToastTypeEnums.WARNING,
            description: msg || `Not Found`,
          });
          break;
        case 429:
          toast({
            title: 'Warning',
            typeIcon: ToastTypeEnums.WARNING,
            description: msg || `You have sent too many requests in a given amount of time`,
          });
          break;
        case 500:
          toast({
            title: 'Error',
            typeIcon: ToastTypeEnums.ERROR,
            description: msg || `Internal Server Error`,
          });
          break;
        case 503:
          toast({
            title: 'Error',
            typeIcon: ToastTypeEnums.ERROR,
            description: msg || `Service is temporarily unavailable`,
          });
          break;
        default:
          console.error(`%c DEBUG LOG: Unexpected error`, 'color: yellow');
          console.error('DEBUG LOG: error.response', error.response);
          break;
      }

      return Promise.reject(error.response ? error.response.data : error);
    }
  );

  return instance;
};

export default axiosInterceptorInstance();
