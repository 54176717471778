import { createStore } from 'zustand/vanilla';
import { GroupChannel, MessageCollection } from '@sendbird/chat/groupChannel';
import { BaseMessage, UserMessage } from '@sendbird/chat/message';
import _ from 'lodash';

export type SendBirdState = {
  currentlyJoinedChannel: GroupChannel | null;
  messages: BaseMessage[] & UserMessage[];
  channels: GroupChannel[];
  file: null | File;
  messageToUpdate: BaseMessage | null;
  messageCollection: MessageCollection | null;
  readMessages: any[];
};

export type SendBirdActions = {
  setCurrentlyJoinedChannel: (ch: GroupChannel) => void;
  setMessageCollection: (mc: MessageCollection) => void;
  setChannels: (channels: GroupChannel[]) => void;
  setMessages: (messages: BaseMessage[] & UserMessage[]) => void;
  setMessageToUpdate: (messageToUpdate: (BaseMessage & UserMessage) | null) => void;
  onMessagesAdded: (channel: GroupChannel, messages: BaseMessage[] & UserMessage[]) => void;
  onMessagesUpdated: (channel: GroupChannel, messages: BaseMessage[] & UserMessage[]) => void;
  onMessagesDeleted: (channel: GroupChannel, messageIds: number[]) => void;
  onChannelsAdded: (channels: GroupChannel[]) => void;
  onChannelsDeleted: (channelsUrl: string[]) => void;
  onChannelsUpdated: (channels: GroupChannel[]) => void;
};

export type SendBirdStore = SendBirdState & SendBirdActions;

export const defaultInitState: SendBirdState = {
  currentlyJoinedChannel: null,
  messages: [],
  channels: [],
  file: null,
  messageToUpdate: null,
  messageCollection: null,
  readMessages: [],
};

export const initSendBirdStore = (): SendBirdState => ({
  currentlyJoinedChannel: null,
  messages: [],
  channels: [],
  file: null,
  messageToUpdate: null,
  messageCollection: null,
  readMessages: [],
});

export const createSendBirdStore = (initState: SendBirdState = defaultInitState) => {
  return createStore<SendBirdStore>()((set) => ({
    ...initState,
    setChannels: (channels: GroupChannel[]) => {
      return set((state) => ({
        ...state,
        channels: _.uniqBy(channels, 'url'),
      }))
    },
    setCurrentlyJoinedChannel: (currentlyJoinedChannel: GroupChannel) =>
      set((state) => ({
        ...state,
        currentlyJoinedChannel,
      })),
    setMessageCollection: (messageCollection: MessageCollection) =>
      set((state) => ({
        ...state,
        messageCollection,
      })),
    setMessages: (messages: BaseMessage[] & UserMessage[]) => {
      const _messages = _.uniqBy(messages, 'messageId');
      return set((state) => ({
        ...state,
        messages: _.orderBy(_messages, ['createdAt'], ['asc', 'desc']),
      }));
    },
    setMessageToUpdate: (messageToUpdate: (BaseMessage & UserMessage) | null) =>
      set((state) => ({
        ...state,
        messageToUpdate,
      })),
    onMessagesAdded: (channel: GroupChannel, messages: BaseMessage[] & UserMessage[]) => {
      return set((state) => ({
        ...state,
        messages: [...state.messages, ...messages],
      }));
    },
    onMessagesUpdated: (channel: GroupChannel, incomingMessages: BaseMessage[] & UserMessage[]) => {
      return set((state) => {
        const updatedMessages = [...state.messages];
        for (let i in incomingMessages) {
          const incomingMessage = incomingMessages[i];
          const indexOfExisting = state.messages.findIndex((message: BaseMessage & UserMessage) => {
            return incomingMessage.reqId === message.reqId;
          });

          if (indexOfExisting !== -1) {
            updatedMessages[indexOfExisting] = incomingMessage;
          }
          if (!incomingMessage.reqId) {
            updatedMessages.push(incomingMessage);
          }
        }
        return {
          ...state,
          messages: updatedMessages,
        };
      });
    },
    onMessagesDeleted: (channel: GroupChannel, messageIds: number[]) =>
      set((state) => {
        const updateMessages = state.messages.filter((message) => !messageIds.includes(message.messageId));
        return {
          ...state,
          messages: updateMessages as BaseMessage[] & UserMessage[],
        };
      }),
    onChannelsAdded: (channels: GroupChannel[]) =>
      set((state) => ({
        ...state,
        channels: [...channels, ...state.channels],
      })),
    onChannelsDeleted: (channelsUrl: string[]) =>
      set((state) => {
        const updatedChannels = state.channels.filter((channel) => !channelsUrl.includes(channel.url));
        return {
          ...state,
          channels: updatedChannels,
        };
      }),
    onChannelsUpdated: (channels: GroupChannel[]) =>
      set((state) => {
        const messages = [...state.messages];
        const updatedChannels = state.channels.map((channel) => {
          const updatedChannel = channels.find((incomingChannel) => incomingChannel.url === channel.url);
          if (updatedChannel) {
            return updatedChannel;
          } else {
            return channel;
          }
        });

        const currentChannel = channels.find((channel) => {
          return state.currentlyJoinedChannel?.url === channel.url;
        });

        const readMessages = [];

        if (currentChannel) {
          currentChannel.unreadMessageCount = 0;

          for (let i in messages) {
            const readMembers = currentChannel.getReadMembers(messages[i]);
            const messageHasBeenRead = readMembers.length > 0;

            if (messageHasBeenRead) {
              readMessages.push(messages[i]);
            }
          }
        }
        return {
          ...state,
          channels: updatedChannels,
          readMessages,
        };
      }),
  }));
};
