'use client';
import { CircleAlert } from 'lucide-react';
import { CaretSortIcon } from '@radix-ui/react-icons';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  ScrollArea,
} from '@/components/common';
import { cn } from '@/lib/utils';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { Tag } from './tag';
import { AnimatePresence, motion } from 'framer-motion';
import lodash from 'lodash';

export interface TagItem {
  label: string;
  value: { id: string | number; [key: string]: any };
}

export interface TagsSelectorProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  hasError?: boolean;
  search?: boolean;
  trigger?: boolean;
  placeholder?: string;
  shouldDefaultFilter?: boolean;
  values?: TagItem[];
  data?: TagItem[];
  onChangeSearch?: (value: string) => void;
  onOpen?: (value: boolean) => void;
  onChoose: (items: TagItem[]) => void;
  onTagClick?: (items: TagItem) => void;
}

const TagsSelector = forwardRef<HTMLSelectElement, TagsSelectorProps>(
  (
    { className, data, hasError, onChoose, onTagClick, onChangeSearch, onOpen, disabled, trigger = false, shouldDefaultFilter = false, values = [], placeholder, search = false },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState<TagItem[]>([]);

    const onChangeCaptureHandler = useCallback(lodash.debounce(onChangeSearch ?? (() => {}), 500), []);


    useEffect(() => {
      if (values) {
        setSelected(values);
      }
    }, [trigger]);


    useEffect(() => {
      onChoose(selected);
    }, [selected]);

    const onSelect = (item: TagItem) => {
      const tags = [...selected, item];
      setSelected(tags);
      setIsOpen(false);
    };

    const onRemove = (tag: TagItem) => {
      setSelected((prev) => {
        const filtered = prev.filter((item) => item.value.id !== tag.value.id);
        return filtered;
      });
    };

    const selectedHas = (id: number | string) => {
      return !!selected.find((tag) => tag.value.id === id);
    };

    const tags = data ? selected : values;

    return (
      <div className={cn('relative w-full', disabled && 'cursor-not-allowed opacity-65')}>
       
        <div
          className={cn(
            'flex min-h-10 w-full rounded-md border border-gray-300 bg-transparent py-1 pl-3 pr-2 text-sm text-slate-900 shadow-sm outline-none transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
            className,
            hasError && 'border-destructive pr-8 invalid:[&:not(:placeholder-shown)]:border-2'
          )}
        >
          <div className={cn("flex w-full flex-row flex-wrap", !disabled && 'cursor-pointer')} onClick={() => !disabled ? setIsOpen(true) : null}>
            <AnimatePresence mode="popLayout">
              {tags?.length === 0 && (
                <span className="flex h-[30px] flex-row items-center text-gray-500">{placeholder}</span>
              )}
              {tags?.map((item) => (
                <motion.div
                  key={item.value.id}
                  layout
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 1.2 }}
                  transition={{ duration: 0.3, type: 'spring' }}
                >
                  <Tag
                    tag={item}
                    onTagClick={onTagClick ? (tag) => onTagClick(tag) : undefined}
                    onRemoveTag={disabled ? undefined : () => onRemove(item)}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </div>

          {!disabled && (
            <Popover open={isOpen} onOpenChange={(e) => {
              setIsOpen(e);
              onOpen?.(e);
            }}>
              <PopoverTrigger asChild>
                <div className='ml-2 h-[30px] min-w-6 flex flex-row justify-center items-center'>
                  <CaretSortIcon className="h-4 w-4 opacity-50 text-gray-900" />
                </div>
              </PopoverTrigger>
              <PopoverContent withoutPortal align="end" className="w-80 p-0">
                <Command shouldFilter={shouldDefaultFilter}>
                  {search && (
                    <CommandInput
                      onValueChange={(value) => {
                        onChangeCaptureHandler(value);
                      }}
                      placeholder="Search..."
                    />
                  )}
                  <CommandList>
                    <ScrollArea className="h-52">
                      <CommandEmpty>No data found.</CommandEmpty>
                      <CommandGroup>
                        {data?.map((item) => {
                          return (
                            <CommandItem
                              value={item.value.id.toString()}
                              disabled={selectedHas(item.value.id)}
                              className="cursor-pointer gap-2"
                              key={item.value.id}
                              onSelect={() => onSelect(item)}
                            >
                              <span className="flex-1 text-sm">{item.label}</span>
                            </CommandItem>
                          );
                        })}
                      </CommandGroup>
                    </ScrollArea>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          )}
        </div>
        {hasError && <CircleAlert className="absolute right-3 top-2 w-4 text-destructive" />}
      </div>
    );
  }
);
TagsSelector.displayName = 'TagsSelector';

export { TagsSelector };
